import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import Logo from "../images/expectium_logo_white.png"
import LogoIcon from "../images/icon.png"
import Thumbnail from "../images/preview.png"
import { Link } from "gatsby"
import Marquee from "react-fast-marquee";

export default function Header(props) {

  const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSticky(window.pageYOffset > 400)
      );
    }
  }, []);

  return (
  <>
    <Helmet>
      <title>Expectium | Social Finance Comes Out</title>
      <meta charset="utf-8"></meta>
      <meta name="description" content="Expectium | Expectium is a decentralized “SocialFi” platform that enables Web3 users to profit from pools by estimating crypto assets under various titles." />
      <meta property="og:image" content={Thumbnail} />
    </Helmet>

    <div class="sliding-text">
      <Marquee gradient={false} speed={20}>
      <ul>
        <li>EXPECTIUM COMES OUT</li>
        <li>IN STARKNET WE TRUST!</li>
        <li>TAKE A POSITION!</li>
        <li>EXPECTIUM COMES OUT</li>
        <li>IN STARKNET WE TRUST!</li>
        <li>TAKE A POSITION!</li>
        <li>EXPECTIUM COMES OUT</li>
        <li>IN STARKNET WE TRUST!</li>
        <li>TAKE A POSITION!</li>
        <li>EXPECTIUM COMES OUT</li>
        <li>IN STARKNET WE TRUST!</li>
        <li>TAKE A POSITION!</li>
        <li>EXPECTIUM COMES OUT</li>
        <li>IN STARKNET WE TRUST!</li>
        <li>TAKE A POSITION!</li>
        <li>EXPECTIUM COMES OUT</li>
        <li>IN STARKNET WE TRUST!</li>
        <li>TAKE A POSITION!</li>
      </ul>
      </Marquee>
    </div>
  <div class="static-header">
    <header>
    <Link className="logo" to="/" onContextMenu={togglePopup} id="logo">
        <img src={Logo} style={{maxWidth:"140px"}} alt="Expectium"/>
      </Link>
      <ul class="menu">
        <li><Link style={{color: 'white'}} to="https://docs.expectium.io">Docs</Link></li>
        <li><Link style={{color: 'white'}} to="https://docs.expectium.io/getting-started/share-nfts">DAO</Link></li>
        <li><Link style={{color: 'white'}} to="https://docs.expectium.io/knowledge-center/faq">FAQ</Link></li>
        <li class="dropdown_menu">
          <button class="dropdown_main" style={{color: 'white'}}>
            Community
          </button>
          <ul class="dropdown">
            <li><a href="https://twitter.com/expectiumio" target="_blank" rel="noreferrer">Twitter</a></li>
            <li><a href="https://discord.gg/TU54zXjNuq" target="_blank" rel="noreferrer">Discord</a></li>
            <li><a href="https://t.me/expectiumprotocol" target="_blank" rel="noreferrer">Telegram</a></li>
            <li><a href="https://t.me/expectiumprotocol" target="_blank" rel="noreferrer">Announcements</a></li>
          </ul>
        </li>
        <li class="gotoapp">
          <a href="https://testnet.expectium.io" class="mainnet_btn" target="_blank" rel="noreferrer">App</a></li>
      </ul>
    </header>
  </div>
  <div className={`fixed-header ${
          sticky ? "sticky" : ""
        }`}>
    <header>
      <Link to="/" onContextMenu={togglePopup}>
        <img src={LogoIcon} alt="Expectium" />
      </Link>
      <ul class="menu">
      <li><Link to="https://docs.expectium.io">Docs</Link></li>
        <li><Link  to="https://docs.expectium.io/getting-started/share-nfts">DAO</Link></li>
        <li><Link to="https://docs.expectium.io/knowledge-center/faq">FAQ</Link></li>
        <li class="dropdown_menu">
          <button class="dropdown_main">
            Community
          </button>
          <ul class="dropdown">
          <li><a href="https://twitter.com/expectiumio" target="_blank" rel="noreferrer">Twitter</a></li>
            <li><a href="https://discord.gg/TU54zXjNuq" target="_blank" rel="noreferrer">Discord</a></li>
            <li><a href="https://t.me/expectiumprotocol" target="_blank" rel="noreferrer">Telegram</a></li>
            <li><a href="https://t.me/expectiumprotocol" target="_blank" rel="noreferrer">Announcements</a></li>
          </ul>
        </li>
        <li class="gotoapp">
          <a href="https://testnet.expectium.io" class="mainnet_btn" target="_blank" rel="noreferrer">App</a></li>
      </ul>
    </header>
  </div>

  </>
  )
}
