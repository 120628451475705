import * as React from "react"
import { Link } from "gatsby"

export default function Header(props) {
  return (
  <div class="container">
      <div class="section-links">
        <section>
          <div class="link_heading">
            <h3>Information</h3>
            <p>In this section we will provide a general overview of the Expectium Protocol and quick links to this document.</p>
           </div>
          <div>
              <div class="helper-link">
                <a href="https://docs.expectium.io/" class="outlink" target="_blank" rel="noreferrer"><h5>Documentation</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://docs.expectium.io/knowledge-center/faq" class="outlink" target="_blank" rel="noreferrer"><h5>Frequently Asked Questions</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://docs.expectium.io/getting-started/tokenomics" class="outlink" target="_blank" rel="noreferrer"><h5>Tokenomics</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://docs.expectium.io/getting-started/road-map" class="outlink" target="_blank" rel="noreferrer"><h5>Roadmap</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://docs.expectium.io/knowledge-center/media-kit" class="outlink" target="_blank" rel="noreferrer"><h5>Brand Assets</h5></a>
              </div>
            </div>
        </section>
        <section>
          <div class="link_heading">
            <h3>Community</h3>
            <p>Community means everything to us. You can join the community, which is the most important part of Expectium, from the section below.</p>
          </div>
          <div>
              <div class="helper-link">
                <a href="https://twitter.com/expectiumio" class="outlink" target="_blank" rel="noreferrer"><h5>Twitter</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://discord.gg/TU54zXjNuq" class="outlink" target="_blank" rel="noreferrer"><h5>Discord</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://t.me/expectiumprotocol" class="outlink" target="_blank" rel="noreferrer"><h5>Telegram</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://t.me/expectiumprotocol" class="outlink" target="_blank" rel="noreferrer"><h5>Telegram Announcements</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://testnet.expectium.io" class="outlink" target="_blank" rel="noreferrer"><h5>App</h5></a>
              </div>
          </div>
        </section>
      </div>
      <footer>
        <p class="disclaimer">All the information on this website - expectium.io - is published in good faith and for general information purpose only. Expectium does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (Expectium), is strictly at your own risk. Expectium will not be liable for any losses and/or damages in connection with the use of our website.
From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.
Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.</p>
        <div class="end">
          <p class="copyright">© 2023 Expectium</p>
        </div>
      </footer>
    </div>
  )
}